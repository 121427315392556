<template>
<v-container style="margin-top:130px;"> 
    <v-container v-if="!linked||1">
       <v-card elevation="10" class="rounded-card" style="margin-bottom:25px;">
            <!-- <v-card-title>
        
           <span class="title font-weight-light">
             NICORA</span>
          </v-card-title> -->
           
            <v-img src="img/new_emo1.png" max-width="100%" contain center></v-img>
           
      </v-card>


      <v-container id="step_0" v-if="step_0">
       <v-row fluid>
      
      <v-col cols="12">
      <p style="text-align: center;">
        <br>
              <b>Збережіть картку FISHKA у профілі для швидкого обміну балів</b>
      </p>
      </v-col>
      
      </v-row>

      <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
            :class="{sinput:$i18n.locale=='ge'}"
              v-model="fishka_num"
              name="fishka_num"
              label="Номер картки Fishka"
              solo
              rounded
              v-maska="'#*'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

         <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-btn rounded class="primary_button" @click="save_fishka">Зберегти</v-btn>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>
      </v-container>

      <v-container id="step_1" v-if="step_1">
       <v-row fluid>
      
      <v-col cols="12">
      <p style="text-align: center;">
        <br>
              <b>З повним переліком партнерів Fishka можна ознайомитись на офіційному сайті Fishka
</b>
      </p>
      </v-col>
      
      </v-row>

      <v-row fluid>
          <v-col cols="0"></v-col>
          <v-col cols="12">
            <v-text-field
            :class="{sinput:$i18n.locale=='ge'}"
              v-model="fishka_send"
              name="fishka_sene"
              label="Кількість балів для обміну"
              solo
              rounded
              v-maska="'#*'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="0"></v-col>
        </v-row>

         <v-row fluid>
            <v-col cols="0"></v-col>
            <v-col cols="12">
                <v-btn rounded class="primary_button" @click="confirm_withdraw = true">Замовити</v-btn>
            </v-col>
            <v-col cols="0"></v-col>
      </v-row>
      </v-container>




          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
    <v-dialog v-model="confirm_withdraw">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_confirm')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
          <center>Бали будуть переведені на номер картки ФІШКА, який закінчується на <b>{{fishka_last}}</b><br>Для підтвердження натисніть кнопку ЗАМОВИТИ.</center>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <span class="link" text @click="confirm_withdraw = false;withdraw_fishka()">   {{$t('confirm_withdraw')}} </span>
          <v-spacer></v-spacer>
          <span class="link" text @click="confirm_withdraw = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    step_0: false,
    step_1: false,
    sms_e:false,
    confirm_withdraw:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    fishka_num:'',
    fishka_last:'',
    fishka_send:'',
    amount:null,
    personal_number: null,
    oppa_account:null,
    orders:[],
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    month_lang:{
       
        'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.fishka_get();
   // this.get_rozetka();
  },
  methods:{
    save_fishka:function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "fishka_save",
          fishka_n:this.fishka_num,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
       if(resp.data=='bad_fishka')
       {
         alert('Введіть коректний номер картки фішка');
       }
       else if(resp.data)
      {
       
        this.step_1=true;
        this.step_0=false;
      }
      else
      {
         this.step_0=true;
      }
        this.isLoading=false;
        
      });
      
    },

    withdraw_fishka: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "fishka_order",
          fishka_sum:this.fishka_send,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
          this.fishka_send='';
         this.get_rozetka();
         alert(this.$t('order_success'));
        }
        else if(resp.data['code']=='insuf')
        {
          alert(this.$t('order_insuf'));

        }
        else if(resp.data['code']=='insuf_min')
        {
          alert("Мінімальна сума - 50 грн.");

        }
        this.isLoading=false;
        
      });
      
    },
    get_rozetka: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_rozetka_eps" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        this.orders=resp.data;
         this.isLoading=false;
        
      });
      
    },


   fishka_get: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "fishka_get" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        
      if(resp.data)
      {
        this.fishka_num=resp.data;
        this.fishka_last=String(resp.data).substr(7);
        this.step_1=true;
      }
      else
      {
         this.step_0=true;
      }
      
        console.log(resp.data); 
         this.isLoading=false;
        
      });
      
    },
    
  }
};
</script>

<i18n>
{
  "ua":{
      "title": "Обмін балів",
      
      "cert_txt":"Для замовлення 1 сертифікату Rozetka номіналом 200 грн натисність «Замовити».  ",
      "btn_order":"Замовити",
      "cert_del_txt":"Для використання сертифікату покажіть його на касі перед покупкою у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.",
      "order_success":"Бали замовлено",
      "order_insuf":"Недостатньо балів",
      "ordered_cert":"Замовлені сертифікати",
      "later_cert":"Сервіс замовлення сертифікатів тимчасово недоступний. Спробуйте пізніше.",
      "confirm_withdraw":"Замовити",
      "close":"Закрити",
      "h_confirm":"Увага"


      

  }
}
</i18n>