<template>
  <v-row fluid>
    <v-col cols="1"></v-col>
    <v-col cols="10" >
      <v-row 
        class="rounded_button"
        align="center"
        justify="center"
        v-ripple="{ center: true }"
        fluid
      >
        <v-col cols="3" class="ma-0 pa-1"
          ><v-img :src="img" max-height="40px" contain></v-img
        ></v-col>
        <v-col cols="9" justify="left"  style="word-break:keep-all;"><slot style="word-break:keep-all;" /></v-col>
      </v-row>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>
</template>

<style scoped>
div {
  word-break:keep-all;
}
</style>

<script>
export default {
  component: "btnrnd",
  name: "btnrnd",
  props: {
    img: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>